/* eslint-disable react/style-prop-object */
/* eslint-disable prettier/prettier */
/* eslint-disable react/no-unescaped-entities */
/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Button,
  ColoredSection,
  DisplayText,
  Header,
  HeroImage,
  LayoutUI,
  LeadText,
  Stack,
  Subheader,
  Tabs,
  TextStyle
} from '../../../ui-kit';

import Layout from '../../../components/layout';
import Navbar from '../../../components/navbar/navbar';
import MobileNavbar from '../../../components/mobile-navbar/mobile-navbar';

import HeaderImage from '../../../assets/images/online-course/header.jpg';
import BookImage from '../../../assets/images/online-course/book.png';

import './OnlineCourse.scss';

import MicroscopeIcon from '../../../assets/icons/microscope.svg';
import StairsIcon from '../../../assets/icons/stairs.svg';
import GlobeIcon from '../../../assets/icons/globe.svg';
import CandlesIcon from '../../../assets/icons/candles.svg';

import WhatIsHappy from '../../../assets/images/online-course/01-what-is-happy.png';
import Obstacles from '../../../assets/images/online-course/02-obstacles.png';
import SelfReflection from '../../../assets/images/online-course/03-selfreflection.png';
import SelfMastery from '../../../assets/images/online-course/04-selfmastery.png';
import Compassion from '../../../assets/images/online-course/05-compassion.png';
import InterDependence from '../../../assets/images/online-course/06-interdependence.png';
import Share from '../../../assets/images/online-course/07-share.png';

const CONTENT = {
  PROGRAM_ICONS: [
    {
      icon: GlobeIcon,
      text: 'World-class experts'
    },
    {
      icon: StairsIcon,
      text: 'New habits to support through thriving'
    },
    {
      icon: MicroscopeIcon,
      text: 'Science-based strategies'
    },
    {
      icon: CandlesIcon,
      text: 'Deep awareness of yourself'
    }
  ],
  TESTIMONIALS: [
    'You gave a lot information to think about. I am definitely not the same person any more and I want continue to discover my new path.',
    'This course should be mentioned constantly on Facebook telling how much it helps the individual!',
    '7 Doors to Happiness was a wonderful opportunity for my students to learn about happiness from several different vantage points, and apply happiness research findings to their own lives. Feedback from the students was overwhelmingly positive, and I plan to offer this option again.'
  ],
  LEARN_TABS: [
    {
      image: WhatIsHappy,
      title: 'What is Happiness?',
      text:
        "You'll learn about short term and long-term happiness, how your brain can actually change, and the power of gratitude."
    },
    {
      image: Obstacles,
      title: 'Obstacles to Happiness',
      text:
        'Learn how to be calm through chaos. It’s inevitable that challenges come up – how you handle them is up to you. Learn how to draw from your own defining moments.'
    },
    {
      image: SelfReflection,
      title: 'Self-Reflection',
      text:
        'Have you ever wondered why you get stressed or even stuck in one way of thinking? If knowledge is power, then self-knowledge is a game changer. Learn a powerful ways to reframe your mindset.'
    },
    {
      image: SelfMastery,
      title: 'Self-Mastery',
      text:
        'If you ever feel that you are hijacked by your emotions, there are strategies that work! Instead of feeling frustration, discover how you can play to your strengths.'
    },
    {
      image: Compassion,
      title: 'Compassion in Action',
      text:
        'If you are a giver with a capital G, you know the effects of putting yourself last. Discover how self-compassion, compassion for others, and the power of forgiveness can empower your life.'
    },
    {
      image: InterDependence,
      title: 'Interdependence',
      text:
        'We all depend on each other. From relationships with people to relationships with the environment, delve into ways to live life as a win-win where everyone benefits.'
    },
    {
      image: Share,
      title: 'Share Your Gift',
      text:
        'This Door taps into your unique gift and the difference it can make to those around you. Emotions are contagious, and your gift will touch others’ lives more than you know. Amplify your presence.'
    }
  ]
};

export default class OnlineCourse extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedTab: 0,
      selectedQuote: 0
    };

    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleQuotePipClick = this.handleQuotePipClick.bind(this);
    this.advanceQuotation = this.advanceQuotation.bind(this);
    this.resetInterval = this.resetInterval.bind(this);
  }

  componentDidMount() {
    this.quoteIntervalHandle = window.setInterval(this.advanceQuotation, 5000);
  }

  componentWillUnmount() {
    window.clearInterval(this.quoteIntervalHandle);
  }

  resetInterval() {
    if (this.quoteIntervalHandle != null) {
      window.clearInterval(this.quoteIntervalHandle);
    }

    this.quoteIntervalHandle = window.setInterval(this.advanceQuotation, 5000);
  }

  advanceQuotation() {
    this.setState(previousState => ({
      selectedQuote:
        (previousState.selectedQuote + 1) % CONTENT.TESTIMONIALS.length
    }));
  }

  handleTabClick(index) {
    this.setState({ selectedTab: index });
  }

  handleQuotePipClick(index) {
    this.resetInterval();
    this.setState({ selectedQuote: index });
  }

  render() {
    return (
      <Layout>
        <Navbar color="white" />
        <MobileNavbar color="white" />
        <main className="page-online-course">
          <HeroImage image={HeaderImage} height="large" overlay="medium">
            <LayoutUI narrow>
              <Stack
                alignment="center"
                distribution="center"
                spacing="loose"
                vertical
              >
                <TextStyle color="white" center>
                  <DisplayText>7 DOORS TO HAPPINESS</DisplayText>
                </TextStyle>
                <TextStyle color="white" center>
                  <p className="HeroSubheading">
                    Create a life you desire – follow our simple online course
                  </p>
                </TextStyle>
                <Button
                  color="yellow-dark"
                  href="https://shop.projecthappiness.org/collections/all-products/products/7-doors-to-happiness-online-course"
                >
                  Order Now
                </Button>
              </Stack>
            </LayoutUI>
          </HeroImage>

          <div>
            <div className="CourseDescriptionSection">
              <div
                className="CourseDescriptionImage"
                style={{
                  backgroundImage: `url(${BookImage})`
                }}
              />
              <div className="CourseDescriptionContainer">
                <Stack spacing="loose" vertical>
                  <Header>
                    Are you looking for ways to create your happiest life?
                  </Header>
                  <LeadText alignment="left">
                    7 Doors to Happiness is designed to help you access your
                    inner awareness, joy, and strengths with knowledge from
                    world-class experts including Dr. Kristen Neff, Tal
                    Ben-Shahar, and Dr. Carol Dweck. Dive in and surpass your
                    own limitations.
                  </LeadText>
                  <Button
                    color="yellow-dark"
                    href="https://shop.projecthappiness.org/collections/all-products/products/7-doors-to-happiness-online-course"
                  >
                    Order Now
                  </Button>
                </Stack>
              </div>
            </div>
          </div>

          <div className="ProgramIconsSection">
            {CONTENT.PROGRAM_ICONS.map(({ icon, text }) => (
              <div className="ProgramIconContainer" key={text}>
                <img src={icon} alt="" className="ProgramIcon" />
                <p>{text}</p>
              </div>
            ))}
          </div>

          <LayoutUI narrow>
            <TextStyle center>
              <Header>Learn About Each Lesson</Header>
              <p className="LessonIntro">
                Each Door introduces you to proven happiness tools to help you
                connect with your core. Catch a glimpse on each lesson below.
              </p>
            </TextStyle>
          </LayoutUI>

          <LayoutUI narrow>
            <Stack distribution="center">
              {CONTENT.LEARN_TABS.map(({ title }, index) => (
                <div
                  className={
                    index === this.state.selectedTab
                      ? 'LearnTabSelector-Active'
                      : 'LearnTabSelector'
                  }
                  key={`tab--${title}`}
                  onClick={() => this.handleTabClick(index)}
                >
                  {index + 1}
                </div>
              ))}
            </Stack>
          </LayoutUI>

          <div className="LearnTabContainer">
            <ColoredSection color="yellow-light">
              <LayoutUI>
                <Tabs height={400} selectedIndex={this.state.selectedTab}>
                  {CONTENT.LEARN_TABS.map(({ title, text, image }, index) => (
                    <Tabs.Pane key={title}>
                      <div className="LearnTab">
                        <div className="LearnImage">
                          <img src={image} alt="" />
                        </div>
                        <Stack vertical>
                          <Subheader>Door{index + 1}</Subheader>
                          <Header>{title}</Header>
                          <p>{text}</p>
                        </Stack>
                      </div>
                    </Tabs.Pane>
                  ))}
                </Tabs>
              </LayoutUI>
            </ColoredSection>
          </div>

          <LayoutUI>
            <Stack spacing="tight" vertical>
              <TextStyle center>
                <Subheader>What people are saying</Subheader>
              </TextStyle>
              <Tabs height={200} selectedIndex={this.state.selectedQuote}>
                {CONTENT.TESTIMONIALS.map(quote => (
                  <Tabs.Pane key={quote}>
                    <div>
                      <Stack distribution="center" alignment="center">
                        <TextStyle center color="gray-dark">
                          <Header size="small">
                            <span className="Quote">"{quote}"</span>
                          </Header>
                        </TextStyle>
                      </Stack>
                    </div>
                  </Tabs.Pane>
                ))}
              </Tabs>

              <div className="TestimonialPipContainer">
                <Stack distribution="center">
                  {CONTENT.TESTIMONIALS.map((quote, index) => (
                    <div
                      key={`pip--${quote}`}
                      className={
                        this.state.selectedQuote === index
                          ? 'TestimonialPip-Active'
                          : 'TestimonialPip'
                      }
                      onClick={() => this.handleQuotePipClick(index)}
                    />
                  ))}
                </Stack>
              </div>
            </Stack>
          </LayoutUI>
        </main>
      </Layout>
    );
  }
}
